import React, {
  ReactNode,
  createContext,
  useState,
} from 'react'
import './reset.css'
import 'fonts/stylesheet.css'
import { ThemeProvider } from 'styled-components'
import theme from './theme'

import { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'

const Header = loadable(
  () => import('./Components/Header/StyledHeader'),
)
const Main = loadable(
  () => import('./Components/Main/StyledMain'),
)
const Footer = loadable(
  () => import('./Components/Footer/StyledFooter'),
)

export const GlobalStyle = createGlobalStyle<{
  isNavOpen: boolean
}>`
  body {
    overflow-y: ${({ isNavOpen }) =>
      isNavOpen ? 'hidden' : 'auto'} !important;
  }
`

export const UserStateContext = createContext(null)

const Layout = ({
  path,
  children,
}: {
  path: string
  children: ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const caseStudies = useStaticQuery(
    graphql`
      query {
        allContentfulCaseStudy {
          nodes {
            label: title
            path
          }
        }
      }
    `,
  ).allContentfulCaseStudy.nodes.map(
    ({ label, path }: { label: string; path: string }) => ({
      label,
      path: '/CaseStudies/' + path,
    }),
  )

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        htmlAttributes={{
          lang: 'en-GB',
        }}
      >
        <meta charSet="UTF-8" />
        <meta
          httpEquiv="x-ua-compatible"
          content="ie=edge"
        />
        <meta
          httpEquiv="x-ua-compatible"
          content="ie=edge"
        />
        <meta
          httpEquiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <meta
          name="description"
          content="Social learning platform to deliver high-quality blended learning with human connection."
        />
        <meta
          name="keywords"
          content="persuasive, influence, Satış eğitimi, satış eğitmeni, satış eğitimleri, Satış teknikleri eğitimi, ikna teknikleri eğitimi, satış koçu, online satış eğitimi, satışçı eğitimi, ileri satış teknikleri, satış pazarlama teknikleri eğitimi"
        />
        <meta
          property="business:contact_data:website"
          content="https://www.suada.com/"
        />

        <meta name="author" content="Suada.com" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta itemProp="name" content="Suada.com" />
        <meta
          itemProp="description"
          content="Social learning platform to deliver high-quality blended learning with human connection."
        />
        <meta
          itemProp="image"
          content="https://ib-assets-public.s3.eu-west-2.amazonaws.com/Everything-final-fixed.jpg"
        />
        {/*Facebook meta tag */}
        <meta
          property="og:url"
          content="https://Suada.com"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Suada.com" />
        <meta
          property="og:description"
          content="Social learning platform to deliver high-quality blended learning with human connection."
        />
        <meta
          property="og:image"
          content="https://ib-assets-public.s3.eu-west-2.amazonaws.com/Everything-final-fixed.jpg"
        />

        {/*twitter meta tag */}

        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:site"
          content="@publisher_handle"
        />
        <meta name="twitter:title" content="Suada.com" />
        <meta
          name="twitter:description"
          content="Social learning platform to deliver high-quality blended learning with human connection."
        />
        <meta
          name="twitter:image:src"
          content="https://ib-assets-public.s3.eu-west-2.amazonaws.com/Everything-final-fixed.jpg"
        />

        {/*OPEN GRAPH*/}
        <meta
          property="og:title"
          content="Suada.com - Social learning platform to deliver high-quality blended learning with human connection."
        />
        <meta property="og:type" content="website" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://ib-assets-public.s3.eu-west-2.amazonaws.com/Everything-final-fixed.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://ib-assets-public.s3.eu-west-2.amazonaws.com/Everything-final-fixed.jpg"
        />
        <meta
          property="og:image:type"
          content="image/jpeg"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="kyc" />
        <meta
          property="og:description"
          content="Social learning platform to deliver high-quality blended learning with human connection."
        />
        <meta property="og:site_name" content="Suada.com" />
      </Helmet>
      <GlobalStyle isNavOpen={isOpen} />
      <Header
        location={path.substring(0, path.length - 1)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        caseStudies={caseStudies}
      />
      <Main onClick={() => setIsOpen(false)}>
        {children}
      </Main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
