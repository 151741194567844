exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-businesses-tsx": () => import("./../../../src/pages/Businesses.tsx" /* webpackChunkName: "component---src-pages-businesses-tsx" */),
  "component---src-pages-consultancy-tsx": () => import("./../../../src/pages/consultancy.tsx" /* webpackChunkName: "component---src-pages-consultancy-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-educators-tsx": () => import("./../../../src/pages/Educators.tsx" /* webpackChunkName: "component---src-pages-educators-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/GDPR.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learners-index-tsx": () => import("./../../../src/pages/Learners/index.tsx" /* webpackChunkName: "component---src-pages-learners-index-tsx" */),
  "component---src-pages-learners-sales-mastery-tsx": () => import("./../../../src/pages/Learners/SalesMastery.tsx" /* webpackChunkName: "component---src-pages-learners-sales-mastery-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-tr-sales-mastery-tsx": () => import("./../../../src/pages/tr/SalesMastery.tsx" /* webpackChunkName: "component---src-pages-tr-sales-mastery-tsx" */),
  "component---src-templates-case-studies-layout-tsx": () => import("./../../../src/templates/caseStudies/layout.tsx" /* webpackChunkName: "component---src-templates-case-studies-layout-tsx" */)
}

