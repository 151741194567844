const theme = {
  colors: {
    primary: '#06c68f',
  },
  placeholders: {
    maxWidth: 'max-width: 1800px; margin: 0 auto;',
    maxWidthBanner: 'max-width: 2000px; margin: 0 auto;',
  },

  breakpoints: {
    wl: '1500px',
    wm: '1200px',
    ws: '1120px',
    wxs: '768px',
  },
  fontSizes: {
    heading: '2.5rem',
    subHeading: '1.5rem',
    body: '1rem',
    bannerHeading: '2rem',
    mobileHeading: '1.5rem',
    mobileSubHeading: '1.2rem',
    mobileBody: '0.8rem',
  },
}

export default theme
